<template>
  <div>
    <compare-dialog
      :compare-dialog-visible.sync="compareDialogVisible"
      :page="preview.page"
      :diff="preview.diff"
      :disable-action="true"
    />
  </div>
</template>
<script>
import apiClient from '@/services'
import 'img-comparison-slider'
import CompareDialog from './components/CompareDialog.vue'

export default {
  components: {
    CompareDialog,
  },
  props: {
    diffId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      compareDialogVisible: true,
      preview: {
        page: {
          title: 'Loading..',
          url: null,
        },
        diff: null,
      },
    }
  },
  created() {
    apiClient.fetchDiffVersion(this.diffId).then(res => {
      this.preview.diff = res.data
      this.preview.page = res.data.page
    })
  },
}
</script>
